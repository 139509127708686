import * as React from 'react';

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel"

type GameCategoryProps = {
	children: React.ReactNode;
};

interface GameCategoryNamespace {
    PaginationDefault: React.FC;
    Title: React.FC<GameCategoryProps>;
    Cards: React.FC<GameCategoryProps>;
}

const GameCategory: React.FC<GameCategoryProps> & GameCategoryNamespace = ({ children, ...props }) => {
	return <div className="flex flex-col gap-y-3 justify-center" { ...props }>{children}</div>;
};

GameCategory.PaginationDefault = () => {
	return (
        <>
            <CarouselNext />
            <CarouselPrevious />
        </>
    );
};

GameCategory.Title = ({ children }: { children: React.ReactNode}) => {
  return (
    <h2 className='flex flex-row font-semibold text-white text-base text-lg items-center gap-2 px-4'>
      { children }
    </h2>
  );
}

GameCategory.Cards = ({ children }: { children: React.ReactNode }) => {
	return (
        <Carousel opts={{
            align: "start",
            loop: true
        }}>
            <CarouselPrevious />
            <CarouselNext />
            <CarouselContent>
                <CarouselItem>
                    { children }
                </CarouselItem>
            </CarouselContent>
        </Carousel>
    );
};

export default GameCategory;
