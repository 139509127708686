import * as React from 'react';

type GameCardProps = {
  children: React.ReactNode;
};

interface GameCardNamespace {
  Information: React.FC<GameCardProps>;
  Banner: React.FC<GameCardProps>;
  Title: React.FC<GameCardProps>;
  Provider: React.FC<GameCardProps>;
}

const GameCard: React.FC<GameCardProps> & GameCardNamespace = ({ children, ...props }) => {
	return <button className="flex flex-col gap-y-2.5 flex-none w-[157px] h-[170px]" { ...props }>{children}</button>;
};

GameCard.Banner = ({ children }: { children: React.ReactNode }) => {
	return (
        <div className="h-28 rounded-xl cursor-pointer">
            {children}
        </div>
    );
};

GameCard.Information = ({ children }: { children: React.ReactNode}) => {
  return (
    <div className='flex flex-col gap-y-1 items-start justify-start'>
      { children }
    </div>
  );
}

GameCard.Title = ({ children }: { children: React.ReactNode }) => {
	return (
        <div className="text-white text-sm font-semibold line-clamp-1 text-left">
            {children}
        </div>
    );
};

GameCard.Provider = ({ children }: { children: React.ReactNode }) => {
	return (
        <div className="text-brandblue-100 text-xs font-medium">
            {children}
        </div>
    );
};

export default GameCard;
